import React from 'react';
import logo from '../perzosoft.bmp';
import {NavLink} from "react-router-dom";

export const Catalogos = () => {
    return (
      <div className="App-body">
      <div>
        <img src={logo} className="App-logo" alt="logo" />
     </div>
      <section className= "seccion">
      <h1>Menú Catálogos</h1>

<ul>
<li>
   <NavLink to= "/empresas">Empresas </NavLink>
</li>
<li>
   <NavLink to= "/cuentas">Cuentas Bancarias </NavLink>
</li>
<li>
   <NavLink to= "/beneficiarios">Beneficiarios </NavLink>
</li>
<li>
   <NavLink to= "/conceptos">Conceptos </NavLink>
</li>
</ul>
      </section>
      </div>
    );
  }

export default Catalogos;