import React from 'react';
import './css/App.css';
import './css/formulario.css';
import './css/Header.css';
// En tu archivo index.js o en cualquier otro archivo donde desees usar Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import bootstrap from 'bootstrap';
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import { Misrutas } from './router/Misrutas';

function App() {
  
  return (
  //  <Store>
   <Misrutas/>
  //  </Store>
            
  );
}

export default App;
