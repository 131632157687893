// import React from 'react'
import React, { useState, useEffect } from 'react';
import '../css/App.css';

export const Menu = ({props, nuevacuenta}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // nombreCuenta, nuevacuenta
  
 const clickseleccion = (id, usuario) =>{
    // console.log(id);
    // console.log(usuario);
    nuevacuenta(id, usuario);
    // seleccion_cuenta
    // console.log(props.nombreCuenta);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('http://localhost:4000/back.php');
        const response = await fetch('https://banco.perzosoft.com/back.php');
        if (!response.ok) {
          throw new Error('No se pudo obtener los datos');
        }
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []); 

  

  if (loading) {
    // Muestra la animación de carga y el logo mientras se están cargando los datos
    return (    
      <div className="App-body">
    
    <section className= "seccion">
    <h1>Cuentas</h1>

    <div className="justify-content-center align-items-center">
      
    <br/>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    </section>
    </div>
      
    );
  }

  if (error) return <p>Error: {error}</p>;
  if (!data) return null; 

  return (
   <div className="App-body">
      
     <section className="seccion">
           <h1>PerzoBanco</h1>
           
           <table className="table table-striped  seleccion_cuenta">
  <thead>
    <tr>
      <th scope="col">CLAVE</th>
      <th scope="col">CUENTA</th>
      <th scope="col">BANCO</th>
      <th scope="col">USUARIO</th>
      <th scope="col">ACCION</th>
    </tr>
  </thead>
  <tbody>
  {data.map(item => (
          <tr >
          <th scope="row">{item.CLAVE}</th>
          <td>{item.CUENTA}</td>
          <td>{item.BANCO}</td>
          <td>{item.USUARIO}</td>
          <td><button onClick={() => clickseleccion(item.CLAVE, item.USUARIO)} type="button" className="btn btn-primary Seleccionar_ok">Seleccionar</button></td>
        </tr>
        ))}
    
  </tbody>
</table>


        </section>
        </div>
  )
}

export default Menu;
